import React, { useState } from 'react';
import '../styles/FAQ.css';

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleQuestion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqData = [
    {
      question: "How does the AI generate testimonials?",
      answer: "The AI uses advanced natural language processing models to analyze key information provided by you, such as product details, customer feedback, and desired tone. Based on this input, it crafts unique and compelling testimonials that resonate with your target audience."
    },
    {
      question: "Can I customize the testimonials generated by the AI?",
      answer: "Yes, you can customize the testimonials by providing specific keywords, adjusting the tone (e.g., professional, casual, enthusiastic), and even specifying the length. The AI adapts to your inputs, allowing you to create testimonials that perfectly align with your brand's voice."
    },
    {
      question: "Is the AI-generated content unique for each user?",
      answer: "Absolutely! Each testimonial is uniquely generated based on the specific inputs you provide. The AI ensures that the content is tailored to your requirements, minimizing the chances of duplication and making each testimonial distinct."
    },
    {
      question: "What languages does the AI support for generating testimonials?",
      answer: "Our AI currently supports multiple languages, including English, Spanish, French, German, and more. We are continually expanding our language offerings to cater to a global audience, ensuring that you can create testimonials that resonate with diverse markets."
    },
    // You can keep your existing FAQ items here if needed
  ];

  return (
    <div className="faq-container">
      <div className="faq-header">
        <h1>Frequently Asked Questions</h1>
      </div>
      <div className="faq-list">
        {faqData.map((item, index) => (
          <div key={index} className="faq-item">
            <div 
              className={`faq-question ${activeIndex === index ? 'active' : ''}`}
              onClick={() => toggleQuestion(index)}
            >
              {item.question}
            </div>
            <div className={`faq-answer ${activeIndex === index ? 'active' : ''}`}>
              {item.answer}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;